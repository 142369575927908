import { Link } from 'react-router-dom';

function Training() {
   return (
      <div className="page">
         <div className="servicepage">
            <div>
               <img src='img/training.webp' className='serviceimg' />
               <h1>Training</h1>
               <div className='mobilepadding'>
                  <a>
                     Das Training ist für Ihre Mitarbeiterinnen und Mitarbeiter gedacht. Diese sind oft sehr erfahren in der Beratung und dem Umgang mit den Menschen, holen jedoch nicht das Optimale aus den Kundenkontakten heraus. Ich biete hierfür Teamevents und individuelle Schulungen an, damit alle profitieren: Der Kunde von einer hervorragenden, freundlichen Beratung, die Ihren Betrieb von allen anderen abgrenzt. Die Mitarbeiterin von einem professionell geführten Gespräch, bei der Sie in Ruhe arbeiten und Lösungen finden kann. Und natürlich Sie, indem nicht nur einfache Zusatzverkäufe Ihren Rohertrag steigern, sondern auch ein zufriedenes und fachlich gebildetes Team das Arbeitsklima bestimmt.
                  </a>
                  <br /><br />
                  <div>
                     <div className='listelementparent'>
                        <li className='listelement'><a>Beratung optimieren bei OTC und Rezept</a></li>
                        <li className='listelement'><a>Kundenhandling in Zeiten von Preiskampf, Lieferengpässen und Zeitmangel</a></li>
                        <li className='listelement'><a>Cross-Selling und Zusatzempfehlungen als Leistung für den Kunden</a></li>
                        <li className='listelement'><a>Fachliche Fortbildungen, u.A. zu Polymedikation</a></li>
                     </div>
                  </div>
                  <div className='spacermobile'></div>
                  <div className="button notselectable center">
                     <Link className='buttonLink' to="/kontakt">Beratungskompetenz verbessern</Link>
                  </div>
                  <div className='serviceother'>
                     <Link to='/beratung'>
                        <img src="img/beratung.webp" className="serviceotherimg serviceleft" />
                        <div>Beratung</div>
                     </Link>
                     <Link to='/coaching'>
                        <img src="img/coaching.webp" className="serviceotherimg serviceright" />
                        <div>Coaching</div>
                     </Link>
                  </div>
               </div>
            </div>
         </div>
      </div>
   )
}

export default Training;