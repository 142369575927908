import { Link } from 'react-router-dom';
import React, { useState } from 'react';

function HideNavBar() {
   let cb = document.getElementById("check") as HTMLInputElement;
   if (cb == null) return;
   cb.checked = false;
   ChangeIcon();
}

function ChangeIcon(icon: string = "☰") {
   let html = document.getElementById('icon') as HTMLElement;
   html.classList.add("rotateIcon");
   console.log(html.classList);
   if (html == null) return;
   html.innerHTML = icon;
}

function ChangeIconToClose() {
   ChangeIcon("✕");
}

function Change() {
   let cb = document.getElementById("check") as HTMLInputElement;
   if (cb == null) return;
   if (cb.checked) ChangeIconToClose();
   else ChangeIcon();
}


function NavBar() {
   return (
      <div className="navbar">
         <nav>
            <input type="checkbox" id="check" onChange={Change}></input>
            <label htmlFor="check" className="checkbtn">
               <a className="notselectable" id='icon'>☰</a>
            </label>
            <Link to="/" onClick={HideNavBar}><img src="img/logo.webp" className="navimg notselectable" /></Link>
            <ul className="navigation">
               <li className="navlistcenter notselectable"><Link to="/" className='navlisteleemnt' onClick={HideNavBar}>Home</Link></li>
               <li className="navlistcenter notselectable"><Link to="/uebermich" className='navlisteleemnt' onClick={HideNavBar}>Über mich</Link></li>
               <li className="navlistcenter notselectable"><Link to="/leistungen" className='navlisteleemnt' onClick={HideNavBar}>Leistungen</Link></li>
               <li className="navlistcenter notselectable"><Link to="/philosophie" className='navlisteleemnt' onClick={HideNavBar}>Philosophie</Link></li>
               {
                  /*
                  <li className="navlistcenter notselectable"><Link to="/preise" className='navlisteleemnt' onClick={HideNavBar}>Preise</Link></li>
                  */
               }
               <li className="navlistcenter notselectable"><Link to="/kontakt" className='navlisteleemnt' onClick={HideNavBar}>Kontakt</Link></li>
               <li className="spacer imprintvisible"></li>
               <li className="notselectable"><Link className="smaller imprintvisible" to="/impressum" onClick={HideNavBar}>Impressum</Link></li>
               <li className="notselectable"><Link className="smaller imprintvisible" to="/datenschutz" onClick={HideNavBar}>Datenschutz</Link></li>
            </ul>
         </nav>
      </div>
   )
}

export default NavBar;