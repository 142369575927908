import { Link } from 'react-router-dom';
import Timeline from "../elements/Timeline";

function Me() {
   return (
      <div className="page">
         <div className="mebox">
            <img src="img/me.webp" className="meimg" />
            <div className="meboxpadding">
               <h1 className="notselectable">Über mich</h1>
               <div className="button notselectable center">
                  <Link className='buttonLink' to="/kontakt">Lernen Sie mich kennen</Link>
               </div>
               <h2 className="notselectable">LEIDENSCHAFT</h2>
               <a className="notselectable">
                  Mein Beruf ist Berufung. Ich brenne für die Pharmazie und Apotheken. Keine teuren Büroräume, kein gestriegelter Anzug, keine Phrasen von Brachenfremden. Vertrauen Sie auf jemanden, dem der Erfolg von öffentlichen Apotheken und Ihr Wohl wirklich am Herzen liegt.
               </a>
               <h2 className="notselectable">ERFAHRUNG</h2>
               <a className="notselectable">
                  Man muss nicht erst 50 werden, um von sich behaupten zu können, erfahren zu sein. Mit meinen 26 Jahren, etlichen Praktika, zahlreichen Jobs und zwei hervorragend abgeschlossenen Studiengängen habe ich mehr gelernt und gesehen als Andere in einem ganzen Leben.
               </a>
               <h2 className="notselectable">KOMPETENZ</h2>
               <a className="notselectable">
                  Durch Einser-Abschlüsse sowohl in Wirtschaftswissenschaften als auch der Pharmazie gepaart mit Allgemeinwissen, Charme, Witz und rhetorischem Können vereine ich, was Sie zu Recht erwarten. Ob ADG, Awinta oder Pharmatechnik - Phoenix, Sanacorp oder Noweda - VSA, DRZ oder ARZ - Dorf-, Innenstadt- oder Centerapotheke: Ich kenne alles. Die Zahlen, die Menschen, die Herausforderungen. Aber vor allem Anderen: Die Potenziale.
               </a>
               <h2 className="notselectable">LEBENSLAUF</h2>
               <div className="timelineparent">
                  <Timeline />
               </div>
            </div>
         </div>
      </div>
   )
}

export default Me;