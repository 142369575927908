import { Link } from 'react-router-dom';

function Philosophy() {
   return (
      <div className="page">
         <div className="mebox">
            <img src="img/philo.webp" className="serviceimg" />
            <h1>Philosophie</h1>
            <div className="meboxpadding">
               <a>
                  Aufgewachsen als Sohn einer selbstständigen Apothekerin liegt mir die Pharmazie im Blut. Erfolgreiche Apotheken sind für mich eine Herzensangelegenheit, denn die Institution Apotheke und die Menschen, auf die sie baut, sind für die Gesundheitsversorgung unerlässlich. Wir sind sozialer Treffpunkt, erster Ansprechpartner, Problemlöser und Vorreiter. Mit einem unglaublich breiten Wissensschatz. Ich möchte das für Sie und die Gesellschaft erhalten.
               </a>
               <br /><br />
               <a>
                  Wir alle wissen um die extrem herausfordernden Aufgaben, die Bürokratie und die stürmischen Zeiten, die noch kommen. Stecken Sie bitte nicht den Kopf in den Sand: Die öffentliche Apotheke macht Spaß, ist vielfältig und interessant! Mit Enthusiasmus und Freude helfe ich Ihnen, ihr Schiff zu steuern.
               </a>
               <br /><br />
               <a>
                  Sie selbst wissen am Besten, womit wir jeden Tag zu kämpfen haben. Es ist enorm was momentan von uns gestemmt werden muss. Dabei gibt es jedoch einfache Möglichkeiten den Alltag für Sie, Ihre Angestellten und auch die Patientinnen und Patienten zu verbessern und am Ende des Tages über verschiedenste Stellschrauben auch Ihren schwer verdienten Ertrag zu steigern. Denn das verdienen Sie.
               </a>
            </div>
            <div className="button notselectable center" id='packbtn'>
               <Link className='buttonLink' to="/kontakt">Packen wir es an</Link>
            </div>
         </div>
      </div>
   )
}

export default Philosophy;