import { useState } from 'react';

function Contact() {
   const [name, setName] = useState("");
   const [pharmacy, setPharmacy] = useState("");
   const [email, setEmail] = useState("");
   const [phone, setPhone] = useState("");
   const [message, setMessage] = useState("");
   const [accept, setAccept] = useState(false);
   const [selectedBeratung, setSelectedBeratung] = useState(false);
   const [selectedCoaching, setSelectedCoaching] = useState(false);
   const [selectedTraining, setSelectedTraining] = useState(false);

   function SelectBtn(id: string) {
      let btn = document.getElementById(id);
      if (btn == null) return;
      btn.classList.toggle("selected");
      if (id == "beratung") setSelectedBeratung(!selectedBeratung);
      if (id == "coaching") setSelectedCoaching(!selectedCoaching);
      if (id == "training") setSelectedTraining(!selectedTraining);
   }

   function Send() {
      if (name.length == 0 || pharmacy.length == 0 || email.length == 0 || message.length == 0 || !accept) {
         alert("Bitte füllen Sie alle Felder aus und akzeptieren Sie die Datenschutzerklärung.");
         return;
      }
      const data = new FormData();
      data.append("name", name);
      data.append("apo", pharmacy);
      data.append("mail", email);
      data.append("tel", phone);
      data.append("body", message);
      data.append("beratung", selectedBeratung ? "true" : "false");
      data.append("coaching", selectedCoaching ? "true" : "false");
      data.append("training", selectedTraining ? "true" : "false");
      console.log(data);
      fetch("https://api.eugenium.eu/mail", {
         method: "POST",
         body: data,
      })
         .then(response => {
            setName("");
            setPharmacy("");
            setEmail("");
            setPhone("");
            setMessage("");
            setAccept(false);
            let cb = document.getElementById("accept") as HTMLInputElement;
            cb.value = "false";
            alert("Ihre Nachricht wurde erfolgreich versendet. Sie sollten in Kürze eine Bestätigung erhalten.");
         })
         .catch(error => alert("Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut oder schreiben Sie eine E-Mail an info@eugenium.eu."));
   }

   return (
      <div className="page">
         <div className="contactsize">
            <div className='servicepage'>
               <img src="img/contact.webp" className="serviceimg notselectable" />
            </div>
            <div className='pagepadding'>
               <h1 className="notselectable">Kontakt</h1>
               <div className='contactcontainer'>
                  <div className="textalign">
                     <a className="notselectable">Name</a>
                     <input placeholder="Name" value={name} onChange={e => setName(e.target.value)} className="inputfield"></input>
                  </div>
                  <div className="textalign">
                     <a className="notselectable">Apotheke</a>
                     <input placeholder="Apotheke" value={pharmacy} onChange={e => setPharmacy(e.target.value)} className="inputfield"></input>
                  </div>
                  <div className="textalign">
                     <a className="notselectable">E-Mail</a>
                     <input placeholder="E-Mail" value={email} onChange={e => setEmail(e.target.value)} className="inputfield"></input>
                  </div>
                  <div className="textalign">
                     <a className="notselectable">Telefon</a>
                     <input placeholder="Telefon" value={phone} onChange={e => setPhone(e.target.value)} className="inputfield"></input>
                  </div>
                  <br />
                  <div className="textalign">
                     <a className="notselectable">Anfrage für</a>
                     <div className="contactselectionbuttons">
                        <div id='beratung' onClick={() => SelectBtn("beratung")} className="selectionButton notselectable">Beratung</div>
                        <div id='coaching' onClick={() => SelectBtn("coaching")} className="selectionButton notselectable">Coaching</div>
                        <div id='training' onClick={() => SelectBtn("training")} className="selectionButton notselectable">Training</div>
                     </div>
                  </div>
                  <br />
                  <div className="textalign">
                     <a className="notselectable">Nachricht:</a>
                     <textarea placeholder="Nachricht" className="textarea" value={message} onChange={e => setMessage(e.target.value)}></textarea>
                  </div>
                  <div>
                     <input type="checkbox" id="accept" onChange={e => setAccept(!accept)}></input>
                     <label htmlFor="accept" className='notselectable'>Ich habe die Datenschutzerklärung gelesen und akzeptiere sie.</label>
                  </div>
                  <br />
                  <div className="sendbutton notselectable" onClick={Send}>Senden</div>
               </div>
            </div>
         </div>
      </div >
   )
}

export default Contact;