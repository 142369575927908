import { Link } from 'react-router-dom';

function Coaching() {
   return (
      <div className="page">
         <div className="servicepage">
            <div>
               <img src='img/coaching.webp' className='serviceimg' />
               <h1>Coaching</h1>
               <div className='mobilepadding'>
                  <a>
                     Das persönliche Coaching ist dafür da, konkrete Ziele für Sie, Ihre Apotheke oder Ihre Mitarbeiter herauszuarbeiten. Welches Profil möchten Sie nach außen repräsentieren, sei es gegenüber Mitarbeiterinnen, Kunden oder Wettbewerbern? Beispiele dafür können sein:
                  </a>

                  <div className='listelementparent'>
                     <li className='listelement'><a>Führungscoaching</a></li>
                     <li className='listelement'><a>Konfliktmanagement</a></li>
                     <li className='listelement'><a>Wettbewerbsabgrenzung</a></li>
                     <li className='listelement'><a>Anpassung der öffentlichen Wahrnehmung</a></li>
                     <li className='listelement'><a>Stressreduktion und Motivationssteigerung</a></li>
                  </div>
                  <div className='spacermobile'></div>
                  <div className="button notselectable center">
                     <Link className='buttonLink' to="/kontakt">Erfolgreich arbeiten</Link>
                  </div>
                  <div className='serviceother'>
                     <Link to='/training'>
                        <img src="img/training.webp" className="serviceotherimg serviceleft" />
                        <div>Training</div>
                     </Link>
                     <Link to='/beratung'>
                        <img src="img/beratung.webp" className="serviceotherimg serviceright" />
                        <div>Beratung</div>
                     </Link>
                  </div>
               </div>
            </div>
         </div>
      </div>
   )
}

export default Coaching;