import './App.css';
import Main from './pages/Main';
import Imprint from './pages/Imprint';
import NavBar from './elements/NavigationBar';
import Me from './pages/Me';
import Services from './pages/Services';
import Philosophy from './pages/Philosophy';
import Prices from './pages/Prices';
import Contact from './pages/Contact';
import Consultation from './pages/Consuldation';
import Coaching from './pages/Coaching';
import Training from './pages/Training';
import Dataprotection from './pages/Dataprotection';
import Footer from './elements/Footer';
import { Route, Routes } from 'react-router-dom';

function analytics() {
  try {
    let url = "https://api.eugenium.eu/analytics/visit";
    fetch(url, {
      method: 'PUT',
    }).catch(error => console.error(error));
  } catch (error) {
    console.error(error);
  }
}

function App() {
  analytics();
  return (
    <div className="App">
      <NavBar />
      <div>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/uebermich" element={<Me />} />
          <Route path="/leistungen" element={<Services />} />
          <Route path="/philosophie" element={<Philosophy />} />
          <Route path="/preise" element={<Prices />} />
          <Route path="/kontakt" element={<Contact />} />
          <Route path="/datenschutz" element={<Dataprotection />} />
          <Route path="/impressum" element={<Imprint />} />
          <Route path="/beratung" element={<Consultation />} />
          <Route path="/coaching" element={<Coaching />} />
          <Route path="/training" element={<Training />} />
        </Routes>
      </div>
      <div className='footerdiv'>
        <Footer />
      </div>
    </div>
  );
}

export default App;
