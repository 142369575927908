function Timeline() {
   return (
      <div className="timeline">
         <div className="line"></div>
         <div>
            <div className="container alignleft">
               <div className="smallball"></div>
               seit Kleinauf: Aufgewachsen in einer Apotheke
            </div>
            <div className="container alignleft">
               <div className="smallball"></div>
               Mit 16 Jahren: Abitur
            </div>
            <div className="container alignleft">
               <div className="smallball"></div>
               2016-2019: Studium der Wirtschaftswissenschaften; Profilbereich Strategische Entscheidungsfindung
            </div>
            <div className="container alignleft">
               <div className="smallball"></div>
               2017-2018: Werkstudent in der strategischen Unternehmensentwicklung
            </div>
            <div className="container alignleft">
               <div className="smallball"></div>
               2019: Abschluss als B.Sc. unter den Top 3% der Universität Hohenheim mit Aufnahme in die Dean's List
            </div>
            <div className="container alignleft">
               <div className="smallball"></div>
               2019-2023: Studium der Pharmazie an der Universität Freiburg
            </div>
            <div className="container alignleft">
               <div className="smallball"></div>
               2021: Erhalt des Deutschlandstipendiums der Bundesrepublik Deutschland
            </div>
            <div className="container alignleft">
               <div className="smallball"></div>
               2022-heute: Projektkoordinator eines Startups
            </div>
            <div className="container alignleft">
               <div className="smallball"></div>
               2023: Abschluss des zweiten Staatsexamens als Jahrgangsbester mit 1,0
            </div>
            <div className="container alignleft">
               <div className="smallball"></div>
               2023-heute: Pharmazeut im Praktikum
            </div>
            <div className="container alignleft">
               <div className="smallball"></div>
               2024: Gründung der Apothekenberatung Eugenium
            </div>
            <div className="container alignleft">
               <div className="smallball"></div>
               jederzeit: überdurchschnittlich motiviert und fleißig
            </div>
         </div>
      </div>
   )
}

export default Timeline;