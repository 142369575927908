import { Link } from 'react-router-dom';

function Services() {
   return (
      <div className="page">
         <div className="servicepage">
            <img src="img/helping.webp" className="serviceimg" />
            <h1>Leistungen</h1>
            <div className="button notselectable center endmargin" id='margin'>
               <Link className='buttonLink' to="/kontakt">Packen wir es an</Link>
            </div>
            <div className='meboxpadding'>
               <a>
                  Meine Leistungen für Sie lassen sich nie komplett voneinander abgrenzen und gehen wahrscheinlich oft Hand in Hand. Denn so ist der Apothekenalltag: vielseitig. Gerne möchte ich Ihnen meine Schwerpunkte kurz vorstellen.
               </a>
               <div className="philoimgcontainer">
                  <Link className="philoimg" to="/beratung" >
                     <img src="img/beratung.webp" className="philoimgleft" />
                     <div>Beratung</div>
                  </Link>
                  <div></div>
                  <Link className="philoimg" to='/training'>
                     <img src="img/training.webp" className="philoimgmiddle" />
                     <div>Training</div>
                  </Link>
                  <div></div>
                  <Link className="philoimg" to="/coaching">
                     <img src="img/coaching.webp" className="philoimgright" />
                     <div>Coaching</div>
                  </Link>
               </div>
            </div>
         </div>
      </div>
   )
}

export default Services;