import { Link } from 'react-router-dom';

function Consultation() {
   return (
      <div className="page">
         <div className="servicepage">
            <img src='img/beratung.webp' className='serviceimg' />
            <div>
               <h1>Beratung</h1>
               <div className='mobilepadding'>
                  <a>
                     Bei der Beratung fokussieren wir uns auf die wirtschaftlichen Aspekte Ihrer Apotheke. Maßgeschneidert für Ihre Situation analysiere ich, wo es Optimierungsmöglichekiten gibt. Diese müssen mit wenig Aufwand umsetzbar und auch in den Alltag integrierbar sein. Hierbei ist es mir wichtig, jeden Schritt mit Ihnen eng abzustimmen und die Möglichkeiten und Bedürfnisse der Apotheke zu berücksichtigen. Erfolg kann stetig verbessert werden.
                  </a>
                  <div className='spacermobile'></div>
                  <div className="button notselectable center btntopmargin">
                     <Link className='buttonLink' to="/kontakt">Rohertrag steigern</Link>
                  </div>
                  <div className='serviceother'>
                     <Link to='/coaching'>
                        <img src="img/coaching.webp" className="serviceotherimg serviceleft" />
                        <div>Coaching</div>
                     </Link>
                     <Link to='/training'>
                        <img src="img/training.webp" className="serviceotherimg serviceright" />
                        <div>Training</div>
                     </Link>
                  </div>
               </div>
            </div>
         </div>
      </div>
   )
}

export default Consultation;