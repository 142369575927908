import { Link } from 'react-router-dom';

function Main() {
   return (
      <div className="pageHome">
         <div className="mainimg notselectable">
            <img className="img" src="img/main.webp" alt="title image" />
         </div>
         <div className="homelayout notselectable">
            <h1>Der Apothekenberater aus Esslingen</h1>
            <br />
            <h3>
               Ist die Apotheke eine Stradivari, bin ich Paganini. <br />
               Keiner spielt wie dieses Dreamteam.
            </h3>
            <br /><br />
            <div className="mainlayoutpadding notselectable">
               <a>
                  Apotheken sind einer der Grundpfeiler der Gesundheitsversorgung. Die Menschen, die diese leiten und dort arbeiten wissen und leisten überproportional viel. Die Belastung dafür: zu hoch. Der Ertrag hingegen: zu gering. Ich helfe Ihnen gerne, um gegenzusteuern.
               </a>
            </div>
         </div>
         <br />
         <div className="homebuttons homelayout">
            <div className="button notselectable">
               <Link className='buttonLink' to="/kontakt">Jetzt anfragen</Link>
            </div>
            <div className="button notselectable">
               <Link className='buttonLink' to="/leistungen">Mehr erfahren</Link>
            </div>
         </div>
         <br />
         <div className="socials homelayout notselectable">
            <a href="https://www.linkedin.com/in/eugenium/"><img className="socialimages" src="/img/linkedin.webp" alt="linkedin" /></a>
            <a href="https://www.instagram.com/eugenium.eu/"><img className="socialimages" src="/img/insta.webp" alt="instagram" /></a>
            <a href="https://www.google.de/maps/place/Eugenium/@50.915856,7.5505733,7z/data=!4m6!3m5!1s0xa2a649a7dad49665:0xd7ad2e070ac43966!8m2!3d51.1758057!4d10.4541194!16s%2Fg%2F11y3jh7ysg?entry=ttu"><img className="socialimages" src="/img/google.webp" alt="google" /></a>
         </div>
         <div>
            <div className="mainlayoutpadding notselectable">
               <a className='opacitytext'>Wir sind nicht für den Inhalt auf den oben verlinkten, externen Seiten verantwortlich.</a>
            </div>
         </div>
         <br />
      </div>
   )
}

export default Main;