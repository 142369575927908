import { Link } from 'react-router-dom';

function Footer() {
   let year = new Date().getFullYear();
   return (
      <footer>
         <div className="footer">
            <div className="footereug">
               <a className="footereugenium" id='eug'>Eugenium &copy;{year}</a>
            </div>
            <div className="footerright">
               <Link to="/impressum" id='imp'>Impressum</Link>
               <Link to="/datenschutz" id='dp' className="dp">Datenschutz</Link>
            </div>
         </div>
      </footer>
   )
}

export default Footer;