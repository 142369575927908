function Imprint() {
   return (
      <div className="page">
         <h1>Impressum</h1>
         <div className="paddingimprint">
            <p>
               Angaben gemäß §5 TMG <br />
               Ausschließliche Nutzung für Zustellungen gemäß §5 Abs. 1 Nr. 2 TMG
            </p>
            <p><b>Eugenium</b><br />
               Tassilo Eugen Mickeler<br />
               Dulkweg 18<br />
               73732 Esslingen</p>
            <h2>Kontakt</h2>
            <p>E-Mail: info@eugenium.eu</p>

            <h2>Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle</h2>
            <p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle
               teilzunehmen. <br />Falls Sie Probleme haben, melden Sie sich bitte per E-Mail und wir werden unser möglichstes tun Ihnen weiterzuhelfen.</p>

            <p>Quelle: <a href="https://www.e-recht24.de">eRecht24</a></p>
         </div>
      </div>
   )
}

export default Imprint;